import React from "react";
import { Link } from '../../atoms/link';

class SidebarMenu extends React.Component {
	
	render() {
        const {items} = this.props;
		return (
			<>
                {items ?
                    <ul className={'sidebarMenu'}>
                        {items?.map((x,i) => (
                            <li>
                                <Link key={i} to={x.link}>{x.label}</Link>
                            </li>
                        ))}
                    </ul>
                    :
                    null
                }
			</>
		);
	}

}

export default SidebarMenu;
