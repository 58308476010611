import React, { Fragment } from "react";
import { Link } from '../../atoms/link';
class assistanceAlert extends React.Component {

	render() {
		return (
			<Fragment>
				<div className={'container assistanceAlert'}>
					<h4>{this.props.title}</h4>
					<ul>
						{this.props.items?.map((x, i) => (
							<li>
								<span>{x.date}</span>
								<span><Link key={i} to={x.link}>{x.event}</Link></span>
							</li>
						))}

					</ul>
				</div>
			</Fragment>
		);
	}

}

export default assistanceAlert;
