import React, { Fragment } from "react";
import Parser from "html-react-parser";
class assistanceIntro extends React.Component {

	render() {
		return (
			<Fragment>
				<div className={'container assistanceIntro'}>
					{this.props.title && <h1>{Parser(this.props.title)}</h1>}
					{this.props.intro && <div className="p">{Parser(this.props.intro)}</div>}
				</div>
			</Fragment>
		);
	}

}

export default assistanceIntro;
