import React, {Fragment} from "react";
import Icon from "../../atoms/icon";
import Button from "../../atoms/button";
import Parser from "html-react-parser";


class assistanceBox extends React.Component {
	
	render() {
		return (
			<>
				{this.props.push
					?
                    <div className={`assistancePush ${this.props.layout ? this.props.layout : ''}`}>
                      <span style={{backgroundImage: `url(${this.props.image})`}}>&nbsp;</span>
					  <div>
                          <h3>{Parser(this.props.title)}</h3>
                          <div className="p">{Parser(this.props.text)}</div>
                          <Button link={this.props.link} label={this.props.label} className={'secondary'}/>
					  </div>
                    </div>
					:
                    <div className={'assistanceBox'}>
                        <Icon name={this.props.icon ? this.props.icon : 'assistance-map-pin'} color={"multicolor"} />
                        <h3>{Parser(this.props.title)}</h3>
                        <div className="p">{Parser(this.props.text)}</div>
                        <Button link={this.props.link} label={this.props.label} className={'secondary'}/>
                    </div>
				}
			</>
		);
	}

}

export default assistanceBox;
