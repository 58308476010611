/**
 * AssistanceBoxes
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class AssistanceBoxes
 * @param {object} props - Props
 */

import React from "react";
// import PropTypes from 'prop-types';
// import { Link } from '../../atoms/link';
import AssistanceBox from '../../components/assistanceBox';
class AssistanceBoxes extends React.Component {

	render() {

		let {items,layout, section_title}  = this.props;
		return (
			<section className={'assistanceBoxes'}>
                {section_title ?
					<div className={'container'}>
					<h2>{section_title}</h2>
					</div>
					:
					null
                }
				<div className={`container assistanceBoxes__${(items).length} ${layout ? layout : ''}`}>
					{
						items.map((item, idx)  => {
							return <AssistanceBox push={item.type === 'push'} image={item.image} key={idx} title={item.title} text={item.text} label={item.label} link={item.link} icon={item.icon} layout={item.layout} />
						})
					}
				</div>
			</section>
		);
	}

}

export default AssistanceBoxes;

