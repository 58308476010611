import React from "react"
import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"
import AssistanceBoxes from '../app/modules/assistanceBoxes'
import AssistanceIntro from '../app/components/assistanceIntro'
import AssistanceAlert from '../app/components/assistanceAlert'
import assistanceItems from '../data/pages/assistance.json';
import SidebarMenu from "../app/components/sidebarMenu";


const AssistancePage = () => {
  const intro = assistanceItems.modules[0];
  const assistance = assistanceItems.modules.filter(item => item.type === "assistanceBoxes3")[0].items.slice(0, 3);
  const assistance2 = assistanceItems.modules.filter(item => item.type === "assistanceBoxes2")[0];
  const assistance3 = assistanceItems.modules.filter(item => item.type === "assistanceBoxes2")[1];
  const push = assistanceItems.modules.filter(item => item.type === "push")[0];
  const push2 = assistanceItems.modules.filter(item => item.type === "push")[1];
  const alert = assistanceItems.modules.filter(item => item.type === "assistanceAlert")[0];

  const breadcrumbs = [
    {
      link: '/',
      label: 'Home'
    },
    {
      label: 'Assistance'
    }
  ];

  const {title: sidebarTitle, items: sidebarItems} = assistanceItems.modules.find(item => item.type === "sidebar");

  return (
    <Layout
        headerActiveItem={3}
        breadcrumbs={breadcrumbs}

        sidebar
        sidebarTitle={sidebarTitle}
        sidebarChildren={SidebarMenu}
        sidebarChildrenProps={{items: sidebarItems}}
    >
      <Seo title="Assistenza" keywords={[`gatsby`, `application`, `react`]} />
      <AssistanceIntro intro={intro.intro} title={intro.title} />
      <AssistanceBoxes items={assistance} />
      <AssistanceBoxes items={assistance2.items.slice(0, 2)} layout={assistance2.layout} />
      <AssistanceBoxes items={[push]} layout={push.layout} section_title={push.section_title} />
      <AssistanceBoxes items={[push2]} layout={push2.layout} section_title={push2.section_title} />
      <AssistanceBoxes items={assistance3.items.slice(0, 2)} layout={assistance3.layout} section_title={assistance3.section_title} />
      <AssistanceAlert items={alert.items} title={alert.title} />
    </Layout>
  )
};

export default AssistancePage;



